import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import getEvents from './SampleEventGenerator';
import SearchFilter from './SearchFilter';

const localizer = BigCalendar.momentLocalizer(moment);

const TaskCalendar = (props) => {
    const sampleEvents = getEvents(
        moment(new Date()).add('-90','days'),
        moment(new Date()).add('90','days'),
    );
    return (
        <div className="page-calendar main">
            <h1>Calendar</h1>
            <SearchFilter />
            <div className="calendar-wrapper">
                <BigCalendar
                    localizer={localizer}
                    events={sampleEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: "100vh" }}
                    min={new Date(2000, 0, 0, 8, 0, 0)} // view starts 8AM
                    max={new Date(2000, 0, 0, 20, 0, 0)} // view ends 8PM
                    onSelectEvent={(e) => props.history.push('/task/id')}
                />
            </div>
        </div>
    );
};

export default TaskCalendar;
