import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import TaskCalendar from './TaskCalendar/TaskCalendar';
import Login from './Login';
import SignUp from './SignUp';
import TaskConfirmation from './TaskDetail/TaskConfirmation';
import MyTasks from './MyTasks/MyTasks';
import TaskDetail from './TaskDetail/TaskDetail';
import ViewProfile from './ViewProfile/ViewProfile';
import Home from './Home';
import HowItWorks from './HowItworks';
import About from './About';
import TaskDetailSignedUp from './TaskDetail/TaskDetailSignedUp';
import TaskCancelConfirmaiton from './TaskDetail/TaskCancelConfirmation';
import OrganizerProfile from './ViewProfile/OrganizerProfile';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    };

    this.handleLoginButtonClicked = this.handleLoginButtonClicked.bind(this);
    this.handleLogoutButtonClicked = this.handleLogoutButtonClicked.bind(this);
  }

  handleLoginButtonClicked(event) {
    event.preventDefault();
    this.setState({
      loggedIn: true
    });
    this.props.history.push('/my-tasks');
  }

  handleLogoutButtonClicked() {
    this.setState({
      loggedIn: false
    });
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="App">
        <Header loggedIn={this.state.loggedIn} handleLogout={this.handleLogoutButtonClicked} />
        <section>
          <Route path="/" exact component={Home}/>
          <Route path="/calendar" component={TaskCalendar}/>
          <Route path="/login" render={() => <Login handleLogin={this.handleLoginButtonClicked} />} />
          <Route path="/become-volunteer" render={() => (<h1>Become a volunteer</h1>)}/>
          <Route path="/organizers" render={() => (<h1>For Organizers</h1>)}/>
          <Route path="/my-tasks" render={MyTasks} />
          <Route path="/confirmation" render={TaskConfirmation} />
          <Route path="/sign-up" render={() => <SignUp handleSignUp={this.handleLoginButtonClicked} />} />
          <Route path="/task/id" render={TaskDetail} />
          <Route path="/task/signedup" render={TaskDetailSignedUp} />
          <Route path="/user" render={ViewProfile} />
          <Route path="/how-it-works" render={HowItWorks} />
          <Route path="/cancel-signup" render={TaskCancelConfirmaiton} />
          <Route path="/about" render={About} />
          <Route path="/organizer-profile" render={OrganizerProfile} />
        </section>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
