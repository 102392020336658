import React from 'react';
import { Link } from 'react-router-dom';
import cleanupImg from './cleanup-img.png';
import testOrganization from './testorga.png';
import locationImg from './location.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faClock, faMapMarkerAlt, faEnvelope, faInfo } from '@fortawesome/free-solid-svg-icons';

const TaskDetailSignedUp = (props) => {
    return (
        <div className="main">
            <div className="warning-box">
                <p><FontAwesomeIcon icon={faInfoCircle} /> You already signed up for this task.</p>
            </div>
            <div className="detail-wrapper">
            <div className="detail-text">
                    <h2>Saturday, 26.04.</h2>
                    <h1>Clean Up Søerne</h1>
                    <div className="category text-center">Environmental</div>
                    <p>Have you also noticed how much garbage is in the streets, lakes, canals and ocean in and around Copenhagen?</p>

                    <p>The first Clean Up initiative takes outset in the lakes surrounding the inner city. The lakes are notoriously plagued by garbage and this especially shows with the recent drought exposing the lake bed to reveal not only plastic on the surface but also on the bottom.<br/>The lakes form home to a variety of birds and fish. The bird population has just seen a boom of new offspring which is at extra high risk to the garbage pollution.

                    They risk not only ingesting it, but also to get tangled up into it.
                    We have to put a stopper to that !</p>

                    <p>Therefore we invite you to join us on Thursday 26th July for a community clean up from 4-6 pm.

                    We hope a lot of people will support this initiative and show up to help, even if just for a short while.</p>

                    <p>If you have any questions then please feel free to ask.</p>

                    <p>N.B. This is a volunteer community initiative.
                    Participation in this event is on your own responsibility.</p>

                    <h3>Additional Information</h3>
                    <ul>
                        <li>We ask that you bring your own remedies for picking up the garbage such as gloves, grippers or the like. You are likewise asked to wear appropriate clothes.</li>

                        <li>Copenhagen Municipal has advised us to encourage people not to enter the lake waters as there can be a risk of bacterial contamination and muddy soil conditions. Should you choose to enter the lakes anyway it is on your own responsibility.</li>

                        <li>We encourage everyone to pick as much trash as possible from the banks and gravel paths using the grippers and your hands.</li>

                        <li>The municipal has agreed to lent us 75 grippers, gloves and large bags which will be handed out at the start of the event at the rally point, where full ones will also be collected. You are welcome to bring your own bags if you have some.</li>
                    </ul>

                    <h3>Supporting partners</h3>
                        <ul>
                            <li><span className="green">REN kærlighed til KBH</span> lending us snappers, gloves, bags, caps etc. and collecting the trash afterwards.</li>

                            <li><span className="green">Happy Helper</span> lending us snappers and bags and will aslo offer coffee/tea to the participating volunteers.</li>

                            <li><span className="green">Kaffesalonen</span> lending us water bikes / swans so we can reach the trash on the water.</li>

                            <li><span className="green">Marathon Sport</span> offering us to use their toilet facilities in their two stores by the lakes and also offer a cup of coffee / energy drink to the participating volunteers (Sortedam Dossering og Åboulevard).</li>
                        </ul>

                    <div className="text-center">
                        <Link to="/cancel-signup" className="button-green button-long">Cancel Signup</Link>
                    </div>
                </div>
                <div className="detail-sidebar">
                    <img src={cleanupImg} className="details-img" alt="" /><br/>
                    <img src={locationImg} className="details-img" alt="Location of task" /><br/>
                    <div className="location-box">
                    <br/>
                        <div className="dashboard-box-details">
                            <div className="icon-wrapper"><FontAwesomeIcon icon={faMapMarkerAlt} /></div>
                            <span><span className="text-bold">MEETING POINT</span><br/>
                            Intersection Sortedams Dossering/Nørrebrogade</span>
                        </div>
                        <div className="dashboard-box-details">
                            <div className="icon-wrapper"><FontAwesomeIcon icon={faClock} /></div>
                            <span><span className="text-bold">TIME</span><br/>
                            16:00 - 18:00</span>
                        </div>
                        <div className="progress-bar">
                            <div className="filler" style={{width: '75%'}} />
                            <span>4 out of 35 slots available</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grey-box organizer-box">
                <div className="dashboard-text">
                    <h3>ABOUT THE ORGANIZER</h3>
                    <p>Formålet med Copenhagen Clean Up initiativet er, at skabe en fælles ejerskabs- og stolthedsfølelse iblandt Københavnerne. Vi håber, at man - via sin aktive og personlige deltagelse i skraldeoprydning - får en lyst til at opretholde en god etik omkring forbrug og afskaffelse af skrald. At man sørger for, at afskaffe sit eget skrald på ansvarlig vis og ikke mindst påminder andre medborgere om deres personlige ansvar, hvis de er uhensigtsmæssige omkring afskaffelsen af deres skrald - en venlig påmindelse fra en medborger kan gøre et stort indtryk, så man næste gang husker, at afskaffe sit skrald på ansvarlig vis.</p> 
                    <p>Lad os gå sammen og Make Copenhagen Clean Again!</p>
                    </div>
                    <div className="organizer-img-wrapper">
                        <Link to="/organizer-profile" className="link-grey"><span>COPENHAGEN CLEAN UP</span>
                        <img src={testOrganization} className="organizer-image" alt="" /></Link>
                        <Link to="/messages" className="link-grey"><span><FontAwesomeIcon icon={faEnvelope} className="icon" />Contact</span></Link>
                    </div>
            </div>
        </div>
    );
}

export default TaskDetailSignedUp;