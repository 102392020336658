import React from 'react';
import { Link } from 'react-router-dom';

const TaskCancelConfirmation = (props) => {
    return (
        <div className="confirmation main">
            <h1 className="text-center">Cancel Confirmation</h1>
            <div className="grey-box text-center box-seventy-percent">
                <h1>Are you sure you want to cancel the task<br/>"Clean Up Søerne"?</h1>
                <p>Keep in mind that you can only cancel no later than 24h before the event.<br/>If you haven't cancelled and don't show up, you will receive a negative rating.</p>
                <div className="text-center">
                    <Link to="/my-tasks" className="button-green button-hollow">No, Back to task</Link>
                </div>
                <div className="text-center">
                    <Link to="/calendar" className="button-green">Yes, Cancel Task</Link>
                </div>
            </div>
        </div>
    );
}

export default TaskCancelConfirmation;
