import React from 'react';
import { Link } from 'react-router-dom';
import girlProfilePhoto from './girlProfilePhoto.jpg';
import testOrganizer from './testorga.png';
import julia from './julia.jpg';
import TaskBoxSmall from './../MyTasks/TaskBoxSmall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

const organizerTasks = [
    {
        img: require("./../MyTasks/cleanup-img.png"),
        title:"Clean Up Nordvest",
        organization: "Copenhagen Clean Up",
        start: new Date(2019,2,19,10,0),
        end: new Date (2019,2,19,13,0),
    },
    {
        img: require("./../MyTasks/sample.jpg"),
        title:"Easter Sample Task",
        organization: "Copenhagen Clean Up",
        start: new Date(2019,3,21,12,0),
        end: new Date (2019,3,21,15,0),
    },
    {
        img: require("./../MyTasks/cleanup-img.png"),
        title:"Clean Up Søerne",
        organization: "Copenhagen Clean Up",
        start: new Date(2019,4,7,16,0),
        end: new Date (2019,4,7,18,0),
    }
]

const OrganizerProfile = (props) => {
    return (
        <div className="main">
            <h1>View Profile</h1>
            <div className="dashboard-box">
                <div className="profile">
                    <div className="img-wrapper">
                            <img src={testOrganizer} className="profile-picture" alt="" />
                            <p className="text-center"><span className="green text-bold">★★★★☆&nbsp;</span>&nbsp;4.1<br/>
                            <span className="font-smaller-uppercase">(13 reviews)</span></p>
                            <Link to="/messages"  className="button-green">Send Message</Link>
                    </div>
                    <div className="dashboard-text">
                        <h2>Copenhagen Clean Up</h2>
                        <div>
                            <span className="text-bold green">Member since August 2018 &nbsp; •<Link to="/report-organizer"><FontAwesomeIcon icon={faFlag} />Report organizer</Link></span>
                        </div>
                        <div>
                            <p>Formålet med Copenhagen Clean Up initiativet er, at skabe en fælles ejerskabs- og stolthedsfølelse iblandt Københavnerne. Vi håber, at man - via sin aktive og personlige deltagelse i skraldeoprydning - får en lyst til at opretholde en god etik omkring forbrug og afskaffelse af skrald. At man sørger for, at afskaffe sit eget skrald på ansvarlig vis og ikke mindst påminder andre medborgere om deres personlige ansvar, hvis de er uhensigtsmæssige omkring afskaffelsen af deres skrald - en venlig påmindelse fra en medborger kan gøre et stort indtryk, så man næste gang husker, at afskaffe sit skrald på ansvarlig vis.</p>
                            <p>Der hersker pt. - i vores optik - en mentalitet omkring, at man blot kan efterlade sit skrald med den formodning, at en anden nok kommer og rydder op efter en. Dette er en farlig mentalitet og en vi bør bekæmpe og ændre. Hvis man køber et produkt som efter forbrug medfører en form for skrald, så er man også ansvarlig for dets ansvarlige afskaffelse.</p>
                            <p>Vi vil med jævne mellemrum arrangere oprydningsevents med forskellige geografiske fokuspunkter i det Københavnske. Vi opfordrer derudover til, at man tager kontakt til os, hvis man ønsker at afholde et oprydningsevent på en bestemt lokation.</p>
                            <p>Lad os gå sammen og Make Copenhagen Clean Again!</p>
                        </div>
                    </div>
                </div>
        </div>
        <h2 className="text-center margin-top">ALL UPCOMING TASKS FROM THIS ORGANIZER</h2>
        <div className="task-box-small-row">
            {organizerTasks.map((organizerTasks) => <TaskBoxSmall task={organizerTasks} /> )}
        </div>
        <hr className="margin-top"></hr>
        <h2 className="text-center margin-top">EXPERIENCES FROM VOLUNTEERS</h2>
        <div className="review-part">
            <div className="review-img-wrapper text-center">
                <span><Link to="/user/id"><img src={girlProfilePhoto} className="reviewer-image" alt="reviewer-img" /><br/>
                Lisa H.</Link></span>
            </div>
            <div>
                <span>CLEAN UP SØERNE &nbsp; | &nbsp; 07.12.2018</span><br/><br/>
                <span>Had a great experience during the event. Hoping there will be more of those!</span>
            </div>
        </div>
        <hr className="review-line" />
        <div className="review-part">
            <div className="review-img-wrapper text-center">
            <span><Link to="/user/id"><img src={julia} className="reviewer-image link-grey" alt="reviewer-img" /><br/>
            Julia B.</Link></span>
            </div>
            <div>
                <span>CLEAN UP SØERNE &nbsp; | &nbsp; 07.12.2018</span><br/><br/>
                <span>I liked it a lot. There were a lot more people and there weren't enough grabbers for everyone, so one star less. Otherwise, I thought it was great. Thanks for organizing such a great event!</span>
            </div>
        </div>
        </div>
    );
}

export default OrganizerProfile;
