import React from 'react';

const locations = [
    "Copenhagen Centre",
    "Vesterbro",
    "Nørrebro",
    "Frederiksberg",
    "Amager",
    "Nordvest",
    "Other",
]

const taskTypes = [
    "Nature & Environment",
    "Cultural Activities",
    "Social Work",
    "Humanitary Work",
    "Educational",
]

const duration = [
    "up to 2h", "2h - 5h", "1 day", "multiple day",
]


const MultiSelector = (props) => {
    return (
        <div className="select-wrapper">
            <select> 
                <option value="" selected disabled hidden>Filter by {props.filterTitle}</option>
                { props.options.sort().map(option => {
                    return (
                        <option value={option.toLowerCase()}>{option}</option>
                    )
                }) }
            </select>
        </div>
    )
}

const SearchFilter = (props) => {
    return (
        <div className="searchfilter-wrapper">
            <MultiSelector options={locations} filterTitle='location' />
            <MultiSelector options={taskTypes} filterTitle='task type' />
            <MultiSelector options={duration} filterTitle='task duration' />
        </div>
    )
};

export default SearchFilter;
