const eventTitles = [
    "Clean up the lakes",
    "Paint walls in our Home for the Homeless",
    "Help reduce food waste",
    "Volunteer in Copenhagen Jazz Festival",
    "Collect waste in the Park",
    "Clean our common areas",
]

function datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function addHours(date, hours) {
    var result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
}

const getEvents = (rangeStartMoment, rangeEndMoment) => {
    const eventList = [];
    const rangeLengthDays = datediff(rangeStartMoment, rangeEndMoment);
    let i = 1;
    const numberEvents = Math.floor(rangeLengthDays*0.6);
    while(i <= numberEvents) {
        const randomTitle = eventTitles[Math.floor(Math.random() * eventTitles.length)];
        let randomStart = new Date(rangeStartMoment.year(), rangeStartMoment.month(), rangeStartMoment.day(), 8, 0);
        randomStart = addDays(randomStart, Math.floor(Math.random() * rangeLengthDays));
        randomStart = addHours(randomStart, Math.floor(Math.random()*8))
        const randomDuration = 1 + Math.floor(Math.random() * 7);
        const randomEnd = addHours(randomStart, randomDuration);
        const newEvent = {
            title: randomTitle,
            start: randomStart,
            end:  randomEnd,
        };
        eventList.push(newEvent);
        i++;
    }
    return eventList;
}

export default getEvents;
