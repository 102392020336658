import React from 'react';
import { Link } from 'react-router-dom';
import cleanupImg from './cleanup-img.png';
import paintWall from './paint-walls.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const TaskBoxSmall = (props) => {
    return (
        <div className="task-box-small-container">
            <Link to="/task/signedup" className="link-grey"><img src={props.task.img} className="text-box-small-img" />
            <span className="margin-top font-smaller-uppercase">{props.task.start.toDateString()}</span><br/>
            <span className="text-bold">{props.task.title}</span><br/>
            <span>{props.task.organization}</span></Link>
        </div>
    )
}

export default TaskBoxSmall;