import React from 'react';
import { Link } from 'react-router-dom';

const TaskConfirmation = (props) => {
    return (
        <div className="confirmation main">
            <h1 className="text-center">Sign Up Confirmation</h1>
            <div className="grey-box text-center box-seventy-percent">
                <h1>Joanna, you are now signed up for the task<br/>"Clean Up Søerne"!</h1>
                <p>Thanks to people like you, the world is getting a bit better.</p>
                <div className="text-center">
                    <Link to="/my-tasks" className="button-green button-hollow">VIEW MY TASKS</Link>
                </div>
                <div className="text-center">
                    <Link to="/calendar" className="button-green">BACK TO CALENDAR</Link>
                </div>
            </div>
        </div>
    );
}

export default TaskConfirmation;
