import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faCheck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const VolunteerTaskBox = (props) => {
    return (
        <div className="dashboard-box text-center">
            <div className="dashboard-text">
                <img src={props.task.img} className="dashboard-image" alt="" />
                <div>
                    <h2>{props.task.title} &nbsp;• &nbsp;{props.task.organization}</h2>
                    <div className="dashboard-box-details">
                        <div className="icon-wrapper"><FontAwesomeIcon icon={faCalendarAlt} /></div><span> {props.task.start.toUTCString()}</span>
                    </div>
                    <div className="dashboard-box-details">
                        <div className="icon-wrapper"><FontAwesomeIcon icon={faMapMarkerAlt} /></div><span> {props.task.location}</span>
                    </div>
                    <div className="dashboard-box-details">
                        <div className="icon-wrapper"><FontAwesomeIcon icon={faCheck} /></div><span> {props.task.status}</span>
                    </div>
                </div>
            </div>
            <Link to="/task/signedup" className="button-green margin-right">View Event</Link> <Link to="/messages" className="button-green">Notifications</Link>
        </div>
    )
}

export default VolunteerTaskBox;