import React from 'react';
import { Link } from 'react-router-dom';
import girlProfilePhoto from './girlProfilePhoto.jpg';
import testOrganizer from './testorga.png';
import plogging from './plogging_union.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

const ViewProfile = (props) => {
    return (
        <div className="main">
            <h1>View Profile</h1>
            <div className="dashboard-box">
                <div className="profile">
                    <div className="img-wrapper">
                            <img src={girlProfilePhoto} className="profile-picture" alt="" />
                            <p className="text-center"><span className="green">★★★★☆</span><br/>13 tasks completed</p>
                            <p><FontAwesomeIcon icon={faMedal} className="green" /><br/>Supervolunteer</p>
                    </div>
                    <div className="dashboard-text">
                        <h2>Hi, I am Joanna!</h2>
                        <div className="dashboard-box-details">
                            <span className="text-bold green">Member since September 2018</span>
                        </div>
                        <div className="dashboard-box-details">
                            <p>Hi there! My name is Joanna from Italy and have been living in lovely Copenhagen for 2 years now. I always wanted to volunteer but never found the time for it as I study at KU and work part-time - so my schedule is very irregular. Then I came across Spare an Hour, that allowed me to volunteer despite my packed schedule! It is great and so far I've been volunteering in super interesting tasks. I'd like to contribute more, and I'm open to anything. I'm an easy-going, responsible person and always excited to get to know new things and people! Maybe I'll see you soon on the next task ;)</p>
                        </div>
                        <div className="dashboard-box-details">
                            <p className="user-details"><span className="text-bold">Languages:</span><br/>Italian, English</p>
                            <p className="user-details"><span className="text-bold">Interests:</span><br/>Travelling, Photography</p>
                        </div>
                    </div>
                </div>
        </div>
        <h2 className="text-center margin-top">REVIEWS FROM ORGANIZERS</h2>
        <div className="review-part">
            <div className="review-img-wrapper text-center">
                <span><img src={testOrganizer} className="reviewer-image" alt="reviewer-img" /><br/>
                <Link to="/organizer-profile" className="link-grey">Copenhagen Clean Up</Link></span>
            </div>
            <div>
                <span>CLEAN UP SØERNE | 07.12.2018</span><br/><br/>
                <span>We were especially glad that Joanna was on board - She was very helpful and took the lead when getting into groups, always motivating others as well. Would very much recommend her!</span>
            </div>
        </div>
        <hr className="review-line" />
        <div className="review-part">
            <div className="review-img-wrapper text-center">
            <span><img src={plogging} className="reviewer-image" alt="reviewer-img" /><br/>
            <Link to="/organizer-profile" className="link-grey">Plogging Union</Link></span>
            </div>
            <div>
                <span>PLOGGING GRØNDALSPARKEN | 06.01.2019</span><br/><br/>
                <span>It was fun going "plogging" with Joanna. Hope to see her again soon!</span>
            </div>
        </div>
        </div>
    );
}

export default ViewProfile;
