import React from 'react';
import { Link } from 'react-router-dom';

const Login = (props) => {
    return (
        <div className="page-login main">
            <h1>Login</h1>
            <div className="full-width-box">
                <h2>Login to existing account</h2>
                <form onSubmit={props.handleLogin}>
                    <input type="email" name="usermail" placeholder="E-mail address" /><br/>
                    <input type="password" name="userpw" placeholder="Password" />
                    <br/>
                    <input type="submit" className="button-green" value="Log in" />
                </form>
            </div>
            <div className="full-width-box">
                <h2>Want to become a volunteer?</h2>
                <Link to="/sign-up" className="button-green">Sign up</Link>
            </div>
            <div className="text-center">
                Are you an organizer? Log in and sign up <Link to="/organizer">here</Link>.
                <br/>
            </div>
        </div>
    );
}

export default Login;
