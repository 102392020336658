import React from 'react';
import { Link } from 'react-router-dom';
import aboutBanner from './how-resized.jpg'

const About = (props) => {
    return (
        <div className="home-wrapper">
            <img src={aboutBanner} className='home-banner-image' />
            <div className="main">
                <div className="home-text-wrapper">
                    <h1 className="text-center">About</h1>
                    <h3>What is Spare An Hour?</h3>
                    <p>Spare An Hour is an online platform, aiming to connect volunteers - no matter if regulars or one-timers - with organizers offering volunteering jobs. The difference to the already existing platforms is that Spare An Hour focuses on short-term jobs only. So tasks, that can be done within a few hours, such as a Saturday afternoon, allowing you maximal flexibility without having you to commit to an organization. You only chose the tasks you want to do, to the time that suits you. Moreover, you don't need to bring any specific skills. Everything you need to know, you will learn on the job.</p>
                    <p>And signing up is absolutely for free. Because we believe that volunteers should not be charged for giving their time for free.</p>
                    <h3 className="margin-top">The story behind it</h3>
                    <p>Spare An Hour was founded in March 2019 as part of a master thesis at Copenhagen Business School. We found that people are (increasingly) willing to volunteer but cannot commit long-term. And since this leads to volunteering potential that is currently not used, even wasted, we wanted to create a solution by connecting short-term volunteers with appropiate tasks, as well as providing organizers the means to reach and manage volunteers, so they can focus on what is important: volunteering.</p>

                    <div className="margin-top grey-box">
                    <h3>Want to get in touch?</h3>
                    <p>Whether you have a question, feedback or want to work with us - we will be happy to hear from you! Just send us an email to <a href="mailto:hello@spareanhour.org">hello@spareanhour.org</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
