import React from 'react';
import { Link } from 'react-router-dom';
import howItWorksImg from './volunteer-resized.jpg'

const HowItWorks = (props) => {
    return (
        <div className="home-wrapper">
            <img src={howItWorksImg} className='home-banner-image' />
            <div className="main">
                <div className="home-text-wrapper">
                    <h1 className="text-center">How it works</h1>
                    <h3>1. Log in</h3>
                    <p>You need to be logged in to sign up for tasks or view your tasks. If you don't already have an account, register <Link to="/sign-up">here</Link>. Remember: It's all free!</p>
                    <h3 className="margin-top">2. Browse Calendar & Sign up for a Task</h3>
                    <p>You can find tasks of organizations in our <Link to="/sign-up">task calendar</Link>. Just click on a task to view more details and sign up. Once signed up, you will receive notifications from the organizer and a reminder.</p>
                    <p>You can also have a look at <Link to="/all-organizers">all organizers</Link>.</p>
                    <h3 className="margin-top">3. Go volunteer</h3>
                    <p>You are all settled - now it's time to do something good! Depending on the organizer and task, you need to check in after arriving to the task location. This will count towards your ratings.</p>
                    <p>You can cancel a task no later than 24h before it without consequences - if you cancel later than that or don't show up, you will receive a negative rating.</p>
                    <h3 className="margin-top">4. After the task</h3>
                    <p>If you want, you can give the organizer a review of the task. They will appreciate a fair feedback.</p>
                    <p>Once completed, your profile will include this task into "tasks completed". After successfully participating in 5 tasks, you will go from "Volunteer Newbie" to "Supvervolunteer". And after 15 tasks, you will become "Volunteer Hero"!</p>

                    <div className="margin-top grey-box">
                    <h3>Are you an organizer?</h3>
                    <p>If you are an organizer and interested in finding volunteers and providing tasks, you can find more information <Link to="/provide-tasks">here</Link>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
