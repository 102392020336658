import React from 'react';
import { Link } from 'react-router-dom';
import homeImg from './home-img-resized.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {
    return (
        <div className="home-wrapper">
            <img src={homeImg} className='home-banner-image' />
            <div className="main">
                <div className="home-text-wrapper">
                    <h1 className="text-center margin-top">Welcome to Spare an Hour</h1>
                    <p>Spare An Hour connects volunteers - no matter if regulars or one-timers - with organizers offering volunteer tasks. More specific, with short-term tasks, that can be done within a few hours, such as a Saturday afternoon, allowing you maximal flexibility without having you to commit to an organization. You only chose the tasks you want to do, to the time that suits you. Moreover, you don't need to bring any specific skills. Everything you need to know, you will learn on the job.</p>
                    <p>And signing up is absolutely for free. Because we believe that volunteers should not be charged for giving their time for free. Interested? Just give it a go!</p>
                    <div className="text-center">
                        <Link to="/calendar" className="button-plain text-center">Go to calendar <FontAwesomeIcon icon={faLongArrowAltRight} /></Link>
                    </div>
                </div>
                <div className="home-cta-wrapper margin-top">
                    <div className="grey-box cta-box">
                        <h2>Log in</h2>
                        <Link to="/login" className="button-green">Log in</Link>
                    </div>
                    <div className="grey-box cta-box">
                        <h2>Not registered yet?</h2>
                        <Link to="/sign-up" className="button-green">Join us</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
