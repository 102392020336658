import React from 'react';
import VolunteerTaskBox from './VolunteerTaskBox';
import TaskBoxSmall from './TaskBoxSmall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

const upcomingTasks = [
    {
        img: require("./food.jpg"),
        title:"Sorting food",
        organization: "Foodsharing Copenhagen",
        start: new Date(2019,2,17,10,0),
        end: new Date (2019,2,17,13,0),
        location: "Homeless Shelter Verstbro",
        status: "Scheduled"
    },
    {
        img: require("./cleanup-img.png"),
        title:"Clean up Søerne",
        organization: "Copenhagen Clean Up",
        start: new Date(2019,3,26,10,0),
        end: new Date (2019,3,26,13,0),
        location:"Intersection Sortedams Dossering/Nørrebrogade",
        status: "Scheduled"
    }
]

const pastTasks = [
    {
        img: require("./running.jpg"),
        title:"Plogging Grøndalsparken",
        organization: "Plogging Union",
        start: new Date(2018,11,13,10,0),
        end: new Date (2018,11,13,13,0),
        location:"Intersection Sortedams Dossering/Nørrebrogade",
        status: "Scheduled"
    },
    {
        img: require("./paintwalls.jpg"),
        title:"Paint Walls",
        organization: "Homeless Shelter Vesterbro",
        start: new Date(2018,9,22,12,0),
        end: new Date (2018,9,22,15,0),
        location: "Homeless Shelter Vesterbro",
        status: "Scheduled"
    },
    {
        img: require("./cleanup-img.png"),
        title:"Clean Up Søerne",
        organization: "Copenhagen Clean Up",
        start: new Date(2018,8,24,16,0),
        end: new Date (2018,8,24,18,0),
        location: "Homeless Shelter Verstbro",
        status: "Scheduled"
    }
]

const MyTasks = (props) => {

    return (
        <div className="dashboard-wrapper main">
                <h1 className="text-center">Hello, Joanna!</h1>
            <div>
                <h2>Your upcoming tasks</h2>
            </div>
            {upcomingTasks.map((upcomingTasks) => <VolunteerTaskBox task={upcomingTasks} /> )}
            <div className="margin-top">
                <h2>Your past tasks</h2>
            </div>
            <div className="task-box-small-row">
                {pastTasks.map((pastTasks) => <TaskBoxSmall task={pastTasks} /> )}
            </div>
            <div className="grey-box margin-top">
            <h3>Your stats</h3>
                <p>Total tasks completed: 13<br/>Total hours volunteered: 39</p>
                <a href="javascript:window.print()">Print certificate <FontAwesomeIcon icon={faFileAlt} /></a>
            </div>
        </div>
    );
}

export default MyTasks;
