import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    return (
      <div className="footer-wrapper">
        <footer className="footer">
          <div>
            <h4><Link to="/">For Volunteers</Link></h4>
            <nav>
              <ul>
                <li><Link to="/how-it-works">How it works</Link></li>
                <li><Link to="/calendar">Calendar</Link></li>
                <li><Link to="/login">Volunteer log in</Link></li>
              </ul>
            </nav>
          </div>
          <div>
            <h4><a href="https://organizer.spareanhour.org">For Organizers</a></h4>
            <nav>
              <ul>
                <li>Why us?</li>
                <li><a href="https://organizer.spareanhour.org/organizer-guide">Guide for Organizers</a></li>
                <li><a href="https://organizer.spareanhour.org/login">Organizer log in</a></li>
              </ul>
            </nav>
          </div>
          <div>
            <h4>Spare An Hour</h4>
            <nav>
              <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/all-organizers">All organizers</Link></li>
                <li><Link to="/privacy-policy">Privacy policy</Link></li>
              </ul>
            </nav>
          </div>
          <div>
            <h4>Contact</h4>
            <nav>
              <ul>
            <li>
              Solbjerg Plads 3<br/>
              DK-2000 Frederiksberg
            </li>
            <li><a href="mailto:hello@spareanhour.org">Email us</a></li>
            </ul>
            </nav>
          </div>
        </footer>
      </div>
    );
}

export default Footer;
